.dialog-add-user {
    .mat-dialog-container {
        padding: 0;
        box-sizing: border-box;

        .mat-dialog-header {
            background-color: #222d32;
            color: #ffffff;
            font-weight: 500;
        }

        .mat-dialog-content {
            margin: 0;
            padding: 20px;
            max-height: inherit;
            height: 100%;
        }

        .mat-dialog-actions {
            margin: 0;
        }

        .mat-horizontal-content-container {
            padding: 0;
        }

        .mat-horizontal-stepper-header-container {
            margin-bottom: 16px;
        }

        .mat-list-item {
            border: 1px solid rgba(0, 0, 0, .12);
            box-sizing: border-box;
            border-bottom: 0;
            background-color: #FFFFFF;
            font-size: 14px;

            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, .12);
            }
        }

        .mat-selection-list {
            outline: none;
        }

        .mat-list-base {
            padding-top: 0;
        }

    }
}
