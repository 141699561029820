
.badge {
    display: inline-block;
    font-size: 85%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    padding: 6px 8px 6px 8px;
    border-radius: 10rem;

    &.badge-default {
        background-color: #333333;
        color: #ffffff;
    }

    &.badge-info {
        background-color: #3099f1;
        color: #ffffff;
    }

    &.badge-success {
        background-color: #28a745;
        color: #ffffff;
    }

    &.badge-danger {
        background-color: #ff5151;
        color: #ffffff;
    }

    &.badge-warning {
        color: #212529;
        background-color: #ffc107;
    }

    &.badge-not-approve {
        color: #ffffff;
        background-color: #D80027;
    }

    &.badge-pending-aprove {
        color: #ffffff;
        background-color: #FFC400;
    }

    &.event-mapping-active-true {
        color: #212529;
        background-color: #ffc107;
    }

    &.event-mapping-active-false {
        color: #fff;
        background-color: #28a745;
    }
}
