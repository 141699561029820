// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "primeflex/primeflex.scss";
@import "components/input-edit-table";
@import "components/badge";
@import "components/dialog-user";
@import "components/modules-dialogs";
@import "components/custom-buttons";
@import "components/form-field";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bit-web-primary: mat.define-palette(mat.$indigo-palette);
$bit-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bit-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bit-web-theme: mat.define-light-theme((
    color: (
        primary: $bit-web-primary,
        accent: $bit-web-accent,
        warn: $bit-web-warn,
    )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bit-web-theme);

// Import theme and material
@import "assets/material/material-icons.css";
@import "assets/themes/theme";
//@import '@angular/material/theming';
@import 'ngx-toastr/toastr';


@mixin utilities {
    @for $i from 0 through 5 {
        .h-#{100 + $i * 10} {
            width: #{(100 + $i * 10) * 1%};
        }
        .w-#{100 + $i * 10} {
            width: #{(100 + $i * 10) * 1%};
        }
        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            .h-sm-#{100 + $i * 10} {
                width: #{100% + $i * 10};
            }
            .w-sm-#{100 + $i * 10} {
                width: #{100% + $i * 10};
            }
        }
    }
}

@include utilities;


.snack-bar {
    color: #fffa16;
    justify-content: center;
}

.alert {
    animation: shake 500ms cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


.fa-regular.fa-pen-to-square,
.fa-regular.fa-trash-alt {
    font-size: 16px;
    color: #2196f3;
    margin-right: 5px;
}

.month-view-icons .fa-pen-to-square,
.month-view-icons .fa-trash-alt {
    font-size: 16px;
    color: white;
    margin-right: 5px;
}
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

mat-form-field.height-auto {

    mat-form-field-infix {
        width: auto;
    }


    .mat-mdc-text-field-wrapper, .mdc-text-field, .mdc-text-field--outlined {
        height: auto !important;

        .mat-mdc-form-field-flex {
            height: auto !important;
        }

        .mat-mdc-form-field-infix {
            height: auto !important;
            padding-top: 12px !important;
        }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 23px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-29.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        transform: var(--mat-mdc-form-field-label-transform);
    }
}

