@use '@angular/material' as mat;
//@import '@angular/material/theming';

// Import font awesome
@import "~@fortawesome/fontawesome-free/css/all.css";

// Import Quill Modules
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

// Import your custom styles and tools
@import "./theme-button";
@import "./theme-badge";

@include mat.core;

mat.$theme-ignore-duplication-warnings:true

;


$custom-typography: mat.define-typography-config(
    $headline-4: mat.define-typography-level(100px, 112px, 300, $letter-spacing: -0.05em),
    $headline-3: mat.define-typography-level(52px, 56px, 400, $letter-spacing: -0.02em),
    $headline-2: mat.define-typography-level(41px, 48px, 400, $letter-spacing: -0.005em),
    $headline-1: mat.define-typography-level(30px, 40px, 400),
    $subtitle-2: mat.define-typography-level(14px, 28px, 400),
    $subtitle-1: mat.define-typography-level(13px, 24px, 400),
    $body-2: mat.define-typography-level(12px, 24px, 500),
    $body-1: mat.define-typography-level(12px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(12px, 14px, 500), // Line-height must be unit-less fraction of the font-size.
);

@include mat.all-component-typographies($custom-typography);
//@include mat.core($custom-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
$primary: mat.define-palette(mat.$grey-palette);
$accent: mat.define-palette(mat.$green-palette);
$success: mat.define-palette(mat.$green-palette);
$info: mat.define-palette(mat.$blue-palette);
$danger: mat.define-palette(mat.$red-palette);
$warn: mat.define-palette(mat.$red-palette);
$alert: mat.define-palette(mat.$yellow-palette);
$edit: mat.define-palette(mat.$grey-palette);
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin custom-theme($theme) {

    @include mat.all-component-themes($theme);

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $main-color: #F4F7F6;
    $strip-color: #F4F7F6;

    html, body, .main {
        margin: 0;
        height: 100%;
    }

    .popover-card {
        max-width: 800px;
        min-width: 300px;
        min-height: 300px;
        max-height: 600px;
        overflow: auto;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        border: 1px solid #4caf4f;

        .popover-title {
            min-height: 32px;
            margin-bottom: 12px;
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            color: #000000d9;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
        }

        .popover-arrow {
            left: 50%;
            transform: translate(-50%);
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            overflow: hidden;
            background: white;
            pointer-events: none;
        }
    }

    .main-header {
        max-width: 100% !important;
        max-height: 64px !important;
        background: mat.get-color-from-palette($primary, 900);
        color: mat.get-color-from-palette($primary, 50);
        border-bottom: 2px solid mat.get-color-from-palette($accent);
        padding: 20px 10px;


        &.mat-toolbar-single-row {
            padding: 20px 10px;
        }
    }

    .main-container {
        height: 100% !important;
        width: 100% !important;

        .mat-sidenav {
            //border: 5px solid red;
            background: #212121;
            //background: mat.get-color-from-palette($primary, 900);
            box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.35);
            font-size: mat.font-size($custom-typography, body-1);
            font-weight: 500;
            text-transform: uppercase;

            mat-nav-list {
                [mat-subheader] {
                    color: mat.get-color-from-palette($primary, 200);
                    font-size: mat.font-size($custom-typography, body-1);
                    font-weight: 500;
                    text-transform: uppercase;
                    opacity: .5;
                    letter-spacing: 1px;
                }

                [mat-list-item] {
                    touch-action: auto !important;
                    height: 40px;
                    color: mat.get-color-from-palette($primary, 50);
                    font-size: mat.font-size($custom-typography, body-1);

                    &.active, &:hover {
                        background-color: mat.get-color-from-palette($accent) !important;
                        color: mat.get-contrast-color-from-palette($accent, 900) !important;
                    }

                    [mat-line] {
                        font-weight: 500;
                    }

                    mat-icon {
                        margin-right: 16px;
                    }
                }
            }

            &.side-filter {
                background-color: $main-color;
                border-top: 0;
                width: 340px;

                .filter {
                    padding: 20px;
                    flex-grow: 0;
                }

                .bt-close {
                    opacity: .3;
                    transition: .2s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .mat-sidenav-content {
            background-color: $main-color;

            .container-header {
                background: mat.get-contrast-color-from-palette($primary, 900);
                z-index: 2;

                .title {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }

            .container router-outlet + * {
                padding: 40px 15px 15px;
                display: block;
                height: 100%;
                box-sizing: border-box;
            }

            .container app-user-mapping-item,
            .container app-user-mapping-results {
                padding: 0;
                height: auto;
            }

            .container-footer {
                min-height: 42px;
                background: mat.get-color-from-palette($primary, 50);
                margin-top: auto;

                span {
                    font-size: mat.font-size($custom-typography, body-1);
                    font-weight: 400;
                }

                @media (min-width: 10rem) and (max-width: 64rem) {
                    min-height: 128px;
                    .phone-size{
                        display: flex;
                        flex-direction: column !important;
                    }
                }
            }
        }
    }

    .mat-expansion-panel {

        .mat-expansion-panel-header {
            background: $strip-color;

            .mat-expansion-panel-header-title {
                font-weight: 600;
            }
        }

        .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
            background: rgba(0, 0, 0, .05);
        }

        .mat-expansion-panel-body {
            padding: 16px;
        }
    }

    .mat-tab-group {

        .mat-tab-header {
            background: $strip-color;

            .mat-tab-label {
                font-weight: 400;
                color: mat.get-color-from-palette($primary, 900);

                &.mat-tab-label-active {
                    color: mat.get-color-from-palette($accent, 900);
                }
            }
        }

        .mat-ink-bar {
            background-color: mat.get-color-from-palette($accent) !important;
        }
    }

    .mat-form-field {

        &.mat-form-field-appearance-outline.mat-form-field-type-mat-chip-list {
            height: inherit;
        }



        &.mat-form-field-appearance-outline {
            height: 60px;

            .mat-form-field-outline-thick {
                color: mat.get-color-from-palette($accent, 700);
            }
        }

        &.mat-focused {
            .mat-form-field-label {
                color: mat.get-color-from-palette($accent, 700);
            }

            .mat-form-field-outline-thick {
                color: mat.get-color-from-palette($accent, 700);
            }

            .mat-form-field-underline .mat-form-field-ripple {
                background: mat.get-color-from-palette($accent, 700);
            }
        }


    }

    .mat-mdc-form-field-error {
        position: absolute;
        padding: 0 5px;
        width: auto !important;
        margin-top: 0;
        top: -55px;
        right: 0;
        color: white !important;
        background: mat.get-color-from-palette($danger, 700);
        border-radius: 4px;
    }

    .mat-action-row {
        margin-top: 20px;
        padding: 16px 8px 0 8px !important;
        border-top-style: none !important;

        button.mat-button {
            margin-left: 8px !important;
            margin-right: 0 !important;
        }
    }

    .mat-card.mat-table, .mat-expansion-panel.mat-table .mat-expansion-panel-body {
        padding: 0;

        .mat-toolbar-row {
            height: 80px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .mat-form-field {
                &.mat-form-field-appearance-legacy {
                    margin-top: 1.25em
                }
            }
        }

        .mat-action-row {
            padding: 16px 16px 0 16px !important;
            border-top-style: none !important;
        }

        .mat-table-overflow {
            overflow: auto;
        }
    }

    table {
        width: 100%;
        overflow: hidden;

        [mat-row] {
            min-height: 44px;
            cursor: pointer;
        }

        .mat-footer-row, .mat-header-row {
            min-height: 48px;
        }

        [mat-header-cell] {
            font-weight: 600;
            color: #333333;
        }

        [mat-row]:hover, [mat-row]:nth-child(odd):hover {
            background-color: mat.get-color-from-palette($accent, 50);
        }

        [mat-header-row], [mat-row]:nth-child(odd) {
            background-color: $strip-color;
        }

        [mat-cell], .mat-footer-cell, [mat-header-cell] {
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 14px;
        }

        &.accordion-table {
            .element-row td {
                border: 0;
            }

            [mat-row]:hover, [mat-row]:nth-child(odd):hover {
                background-color: #ffffff;
            }

            .detail-row {
                [mat-row]:hover, [mat-row]:nth-child(odd):hover {
                    background-color: mat.get-color-from-palette($accent, 50);
                }
            }
        }

        @media screen and (max-width: 960px) {
            border: 0;
            vertical-align: middle;

            [mat-header-row] {
                display: none !important;
            }

            [mat-row] {
                min-height: 22px;
                border-bottom: 2px solid rgba(0, 0, 0, 0.25);
                display: block !important;
            }

            [mat-cell] {
                display: flex;
                text-align: right;
                font-weight: bold;
                max-width: none !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }

            [mat-cell]:before {
                display: flex;
                flex: 1 1 0;
                content: attr(data-header);
                float: left;
                text-transform: uppercase;
                font-weight: normal;
            }

            [mat-cell]:last-child {
                border-bottom: 0;
            }

            [mat-cell]:first-child {
                padding-top: 4%;
            }

            td[mat-cell]:first-child {
                padding-top: 0;
            }

            tr[mat-row] {
                height: auto;
                min-height: auto;
            }

            .detail-row {
                height: 0;
                border-bottom: 0;

                .element-detail {
                    width: 100%;
                }
            }

        }
    }

    .mat-raised-button.mat-accent,
    .mat-fab.mat-accent,
    .mat-mini-fab.mat-accent {
        color: mat.get-contrast-color-from-palette($primary, 900);
    }

    .mat-mdc-dialog-container {
        padding: 0 !important;

        .mat-dialog-header {
            background-color: mat.get-color-from-palette($primary, 900);
            color: mat.get-color-from-palette($primary, 50);
            font-weight: 500;
            max-height: 52px !important;
            min-height: 52px !important;
        }

        .mat-dialog-content {
            margin: 0;
            padding: 20px;
        }

        .mat-dialog-actions {
            margin: 0;
        }
    }

    mat-icon {
        &.fas {
            font-size: 18px !important;
            width: 20px !important;
            height: 18px !important;
            line-height: 18px !important;
        }
    }

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
        color: mat.get-contrast-color-from-palette($accent, 900);
    }

    .bt-search {
        margin-top: 7px !important;
    }

    .arrow-areas {
        width: 100%;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 500;
        color: #78909C;
        text-align: center;
        line-height: 1.3;
    }

    .steps-container {

        .step-number {
            width: 36px;
            height: 36px;
            border: 3px solid #4caf50;
            color: #4caf50;
            border-radius: 60px;
            text-align: center;
            font-size: 18px;
            line-height: normal;
            font-weight: 600;
            background-color: #e2f1e2;
        }

        .step-item {
            text-align: start;
            min-height: 250px;

            .txt-step {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.3;
            }

            img {
                width: 100%;
                margin-top: auto;
            }
        }

    }

    .star-icon {
        font-size: 22px !important;
        margin: 2px !important;
        line-height: inherit !important;
        color: #78909c !important;
    }

    .star-icon.half100:before {
        height: 22px !important;
        top: 3px !important;
    }

    .star-icon:before {
        color: mat.get-color-from-palette($accent) !important;
    }

    .help-menu-content {
        padding: 20px 30px 20px 0;

        ol {
            margin: 0;
            list-style: none;
            counter-reset: my-awesome-counter;
        }

        ol li {
            counter-increment: my-awesome-counter;
            padding: 8px 0;
            color: rgba(0, 0, 0, .54);
        }

        ol li::before {
            content: counter(my-awesome-counter) ". ";
            color: #4baf50;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .mat-success {
        color: mat.get-color-from-palette($primary, 50) !important;
    }

    .module {
        &.name > button {
            text-transform: uppercase;
            letter-spacing: .50px;
            background-color: mat.get-color-from-palette($accent);
            color: #FFFFFF;
        }
    }

    .mat-form-field-infix {
        width: auto !important;
    }

    .validator-card {
        border: 1px solid #CCCCCC;
        border-right: 0;
        padding: 16px 16px 0 16px;
        text-align: center;

        &:last-child {
            border-right: 1px solid #CCCCCC;
        }

        .names {
            font-size: 28px;
            font-weight: 600;
            line-height: normal;
        }

        .total {
            font-size: 72px;
            font-weight: 600;
        }

        .card-head {
            width: 100%;

            h2 {
                background-color: mat.get-color-from-palette($accent);
                min-width: 120px;
                text-align: center;
                border-radius: 100px;
                padding: 8px 0;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .help-filter {
        width: 400px;
        padding-left: 30px;
        padding-top: 3px;
        color: rgba(0, 0, 0, .5);
        font-size: 1.2em;
    }
    .help-filters-text {
        padding-left: 30px;
        padding-top: 3px;
        font-size: 1.2em;
        color: rgba(0, 0, 0, .5);
    }

    .badgeButton.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -4px;
    }

    .badgeButton.mat-badge-medium.mat-badge-above .mat-badge-content {
        top: -4px;
    }

    .badgeButton .mat-badge-content {
        color: mat.get-color-from-palette($accent);
        box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
        border: 1px solid mat.get-color-from-palette($accent);
        background-color: #FFFFFF;
    }

    .file_input {
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        outline: 0;
        border: 1px solid mat.get-color-from-palette($accent);
        -webkit-tap-highlight-color: transparent;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        line-height: 36px;
        padding: 0 16px 0 8px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 0;
        background-color: transparent;
        color: mat.get-color-from-palette($accent);
        transform: translate3d(0, 0, 0);
        transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);

        &:hover {
            background-color: #F1EBEF;
        }

        &.disabled {
            background-color: #e0e0e0;
            color: #ffffff;
            box-shadow: none;
        }

        .material-icons {
            margin: 0 6px 0 0;
            cursor: pointer;
            line-height: inherit;
        }

        span {
            cursor: pointer;
            margin-right: 5px;
        }

        .image_input_button {
            display: flex;
        }
    }

    .plan-buttons {
        min-width: 0 !important;
        padding: 0 8px !important;
        border: 1px solid mat.get-color-from-palette($accent) !important;
        border-radius: 4px !important;

        &.confirm-import {
            border-radius: 0 4px 4px 0 !important;
        }

        &:disabled {
            border: 1px solid mat.get-color-from-palette($accent) !important;
        }
    }

    .mat-success {
        color: #FFFFFF !important;
        background-color: #4caf50 !important;

        &:disabled {
            background-color: rgba(0, 0, 0, .12) !important;
        }
    }

    .mat-info {
        color: #FFFFFF !important;
        background-color: #2196f3 !important;

        &:disabled {
            background-color: rgba(0, 0, 0, .12) !important;
        }
    }

    .mat-danger {
        background-color: #ff5151 !important;
        color: #ffffff !important;

        &:disabled {
            background-color: rgba(0, 0, 0, .12) !important;
        }
    }

    .item-history {
        border-left: 1px solid rgba(0, 0, 0, .12);
        padding-left: 24px;
        margin: 0 16px 0 30px;
        font-size: 14px;
        position: relative;


        &:first-child {
            .expansion-panel {
                margin-bottom: -1px;
                border-radius: 0;

                .panel-header {
                    border-top-left-radius: 4px !important;
                    border-top-right-radius: 4px !important;
                }
            }

            .icon {
                top: 0;
                padding-top: 12px;
            }
        }

        .icon {
            position: absolute;
            top: 10px;
            left: -14px;
            background-color: white;
            border-radius: 50%;
            height: 24px;
            color: mat.get-color-from-palette($primary, 800);
        }

        .expansion-panel {
            margin-bottom: -1px;
            border-radius: 0;

            .panel-header {
                border: 1px solid mat.get-color-from-palette($primary, 300);

                &.mat-expanded {
                    border-bottom: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .mat-expansion-panel-header-description {
                    width: 180px;
                    max-width: 180px;
                    font-weight: 900;
                    color: mat.get-color-from-palette($primary);
                }

            }

            &.mat-expansion-panel:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }

            &.mat-expansion-panel .mat-expansion-panel-body {
                border: 1px solid mat.get-color-from-palette($primary, 300);
                border-radius: 0;
            }

            .mat-list-base .mat-list-item {
                background-color: mat.get-color-from-palette($primary, 200);
                height: auto;
                margin-bottom: 2px;
                padding: 6px 0;

                &:nth-child(odd) {
                    background-color: mat.get-color-from-palette($primary, 200);
                }
            }

            &:last-of-type {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:first-of-type {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .history-no-records-found {
            border: 1px solid mat.get-color-from-palette($primary, 200);
            padding: 16px;
            background-color: mat.get-color-from-palette($primary, 200);
            border-radius: 4px 4px 0 0;
        }
    }

    .bg-modules {
        flex: 1;
    }
}

@include custom-theme($theme);

// Level Up theme.
$levelup-accent: mat.define-palette(mat.$green-palette);
$levelup-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $levelup-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.levelup-theme {
    @include custom-theme($levelup-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($levelup-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-levelup.svg");
        }
    }
}

.levelup-theme .full-screen-dialog .mat-dialog-container {
    background-color: #4CAF50 !important;
}

// Restaurant theme.
$restaurant-accent: mat.define-palette(mat.$orange-palette, 800);
$restaurant-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $restaurant-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.restaurant-theme {
    @include custom-theme($restaurant-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($restaurant-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-restaurant.svg");
        }
    }
}

// Purchases.
$purchases-accent: mat.define-palette(mat.$light-green-palette, 900);
$purchases-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $purchases-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.purchases-theme {
    @include custom-theme($purchases-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($purchases-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-purchasing.svg");
        }
    }
}

.purchases-theme .full-screen-dialog .mat-dialog-container {
    background-color: #31651D !important;
}

// Settings.
$settings-accent: mat.define-palette(mat.$blue-gray-palette);
$settings-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $settings-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.settings-theme {
    @include custom-theme($settings-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($settings-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-settings.svg");
        }
    }
}

.settings-theme .full-screen-dialog .mat-dialog-container {
    background-color: #5D7786 !important;
}

// Rh.
$rh-accent: mat.define-palette(mat.$blue-palette, A700);
$rh-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $rh-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.rh-theme {
    @include custom-theme($rh-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($rh-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-settings.svg");
        }
    }

    // labs
}

.rh-theme .full-screen-dialog .mat-dialog-container {
    background-color: #2196F3 !important;
}

$labs-accent: mat.define-palette(mat.$deep-purple-palette);
$labs-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $labs-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.labs-theme {
    @include custom-theme($labs-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($labs-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-settings.svg");
        }
    }
}

.labs-theme .full-screen-dialog .mat-dialog-container {
    background-color: #6237B0 !important;
}

$registration-accent: mat.define-palette(mat.$orange-palette, A700);
$registration-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $registration-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.registration-theme {
    @include custom-theme($registration-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($registration-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-settings.svg");
        }
    }
}

.registration-theme .full-screen-dialog .mat-dialog-container {
    background-color: #F49200 !important;
}

// patrimony.
$patrimony-accent: mat.define-palette(mat.$teal-palette);
$patrimony-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $patrimony-accent,
        success: $success,
        info: $info,
        danger: $danger,
        warn: $warn,
        alert: $alert,
        edit:$edit,
    )
));

.patrimony-theme {
    @include custom-theme($patrimony-theme);

    &.module {
        &.active, &:hover {
            background-color: mat.get-color-from-palette($patrimony-accent);
        }

        .icon {
            content: url("~src/assets/images/logo-module-settings.svg");
        }
    }
}

.patrimony-theme .full-screen-dialog .mat-dialog-container {
    background-color: #009083 !important;
}

.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;

    mat-icon {
        align-items: center;
        justify-content: center;
    }
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.filter-size{
    margin-top: -1px !important;
    max-height: 25px;
}

.no-records-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 14px 24px;
}
