.small-input {
    margin-top: 24px;

    &.mat-form-field-disabled {
        margin-top: 16px !important;

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix input,
        .mdc-text-field .mat-mdc-form-field-infix input,
        .mdc-text-field--outlined .mat-mdc-form-field-infix input {
            color: #333333;
        }

        .mat-mdc-text-field-wrapper,
        .mdc-text-field,
        .mdc-text-field--outlined {
            padding: 0;
            height: auto !important;
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-select-arrow-wrapper {
            display: none;
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: transparent !important;
        }

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix,
        .mdc-text-field .mat-mdc-form-field-infix,
        .mdc-text-field--outlined .mat-mdc-form-field-infix {
            padding: 0 !important;
        }

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
        .mdc-text-field .mat-mdc-form-field-flex,
        .mdc-text-field--outlined .mat-mdc-form-field-flex {
            height: 0 !important;
        }
    }
}
