.modules-dialog {
    margin: 0 !important;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100% !important;

    .mat-dialog-container {
        background: rgb(7, 59, 91) !important;
        background: linear-gradient(45deg, rgba(7, 59, 91, 1) 31%, rgba(30, 89, 110, 1) 57%, rgba(56, 122, 132, 1) 80%, rgba(107, 187, 174, 1) 100%) !important;
        border-radius: 0;

        .mat-dialog-content {
            max-height: none;
        }
    }
}
