
mat-form-field {

    mat-form-field-infix {
        width: auto;
    }


    .mat-mdc-text-field-wrapper, .mdc-text-field, .mdc-text-field--outlined {
        height: 40px;

        .mat-mdc-form-field-flex {
            height: 40px !important;
        }


        .mat-mdc-form-field-infix {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            height: 40px !important;
            min-height: 40px;

            input {
                height: 24px !important;
            }
        }

        .mat-mdc-form-field-icon-suffix {
            app-autocomplete-control {
                display: flex;
                justify-content: flex-end;
                min-width: 150px;
            }
        }

        .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
            .mat-icon {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 18px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-24.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        transform: var(--mat-mdc-form-field-label-transform);
    }
}
