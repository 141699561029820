.floating-button {
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 2;

    &.history {
        top: 7px;
        right: 25px;
    }
}
